import _ from 'lodash';
import z from 'zod';

import {
  BooleanOperator,
  CollectionOperator,
  ComparisonOperator,
  Condition,
  ConditionCollection,
  Conditions,
  OperatorResult,
  Timezone,
  InstanceScope,
  IReadOnlyAnswerResolver,
  NodeIdInstanceScope,
} from '@breathelife/types';

import { isConditionCollection, isConditionQuery, isConditions, isConditionSingleField } from './conditions';
import { assertUnreachable } from './helpers/assertUnreachable';
import { evaluateQuery } from './queries';

export type EvaluateConditionsValues = {
  isValid: boolean;
};

export function evaluateConditions(
  conditions: Conditions,
  resolver: IReadOnlyAnswerResolver,
  scope: NodeIdInstanceScope,
  timezone: Timezone,
  currentDateOverride: string | null,
): EvaluateConditionsValues {
  const evaluatedConditions = _.map(conditions.conditions, (condition: Condition): boolean => {
    if (isConditionCollection(condition)) {
      const collectionEvaluation = evaluateCollectionCondition(
        condition,
        resolver,
        scope,
        timezone,
        currentDateOverride,
      );
      return collectionEvaluation.isValid;
    }

    const isNestedCondition = _.has(condition, 'conditions');
    if (isNestedCondition) {
      const nestedEvaluation = evaluateConditions(
        condition as Conditions,
        resolver,
        scope,
        timezone,
        currentDateOverride,
      );
      return nestedEvaluation.isValid;
    }

    const itemEvaluation = evaluateItemCondition(condition, resolver, scope, timezone, currentDateOverride);
    return itemEvaluation.isValid;
  });

  if (conditions.operator === BooleanOperator.or) {
    return { isValid: _.some(evaluatedConditions, Boolean) };
  } else if (conditions.operator === BooleanOperator.not) {
    if (evaluatedConditions.length !== 1) {
      throw Error('BooleanOperator.not only supports 1 top-level condition. Use nesting to reverse other conditions.');
    }
    return { isValid: !evaluatedConditions[0] };
  }

  // `BooleanOperator.and` is the default.
  return { isValid: _.every(evaluatedConditions, Boolean) };
}

function evaluateItemCondition(
  condition: Condition,
  resolver: IReadOnlyAnswerResolver,
  scope: NodeIdInstanceScope,
  timezone: Timezone,
  currentDateOverride: string | null,
): EvaluateConditionsValues {
  let controlValue = evaluateControlValue(condition, resolver, scope, timezone, currentDateOverride);
  if (isConditionQuery(condition)) {
    if (!controlValue && condition.nodeId) {
      controlValue = resolver.usingNodeId().getAnswer(condition.nodeId, scope);
    }

    const queryResult = evaluateQuery(condition.query, resolver, scope, timezone, {}, currentDateOverride);

    return { isValid: evaluateCondition(condition, queryResult, controlValue) };
  } else if (isConditionSingleField(condition)) {
    const value = resolver.usingNodeId().getAnswer(condition.nodeId, scope);
    return { isValid: evaluateCondition(condition, value, controlValue) };
  }

  // TODO: Would be better to have the Condition's structure Typegate Schema
  // TODO: message instead of serializing the object later.
  throw new Error(`Unexpected Condition type: ${JSON.stringify(condition)}`);
}

function evaluateControlValue(
  condition: Condition,
  resolver: IReadOnlyAnswerResolver,
  scope: InstanceScope,
  timezone: Timezone,
  currentDateOverride: string | null,
): unknown {
  if (isConditions(condition)) {
    throw new Error('Invalid Condition Type');
  } else if (condition.controlValue && condition.controlValueQuery) {
    throw new Error('Only one of controlValue or controlValueQuery can be set');
  }

  let value: OperatorResult | unknown;

  if (condition.controlValue) {
    value = resolver.usingNodeId().getAnswer(condition.controlValue, scope);
  } else if (condition.controlValueQuery) {
    value = evaluateQuery(condition.controlValueQuery, resolver, scope, timezone, {}, currentDateOverride);
  } else {
    value = condition.value;
  }

  return value;
}

export function evaluateCondition(condition: Condition, value: unknown, controlValue: unknown): boolean {
  switch (condition.operator) {
    case ComparisonOperator.equal:
      return _.isEqual(value, controlValue);
    case ComparisonOperator.notEqual:
      return !_.isEqual(value, controlValue);
    case ComparisonOperator.greaterThan:
      return convertToComparableValue(value) > convertToComparableValue(controlValue);
    case ComparisonOperator.greaterThanOrEqual:
      return convertToComparableValue(value) >= convertToComparableValue(controlValue);
    case ComparisonOperator.lessThan:
      return convertToComparableValue(value) < convertToComparableValue(controlValue);
    case ComparisonOperator.lessThanOrEqual:
      return convertToComparableValue(value) <= convertToComparableValue(controlValue);
    case ComparisonOperator.multipleOf:
      const dividend = convertToComparableValue(value);
      const divisor = convertToComparableValue(controlValue);
      return Number(dividend) % Number(divisor) === 0;
    case ComparisonOperator.inRange:
      return inRange(value, controlValue);
    case ComparisonOperator.notEmpty:
      return notEmpty(value);
    case ComparisonOperator.isEmpty:
      return !notEmpty(value);
    case ComparisonOperator.matchesAny:
      return matchesAnyOperator(value, controlValue);
    case ComparisonOperator.matchesNone:
      return !matchesAnyOperator(value, controlValue);
    case ComparisonOperator.characterCountBetween:
      return inRange(String(value).length, controlValue);
    case ComparisonOperator.matchesRegex:
      return matchesRegex(value, controlValue);
    default:
      return true;
  }
}

function matchesRegex(value: unknown, regex: unknown): boolean {
  if (!value) {
    return true;
  }
  let stringValue;
  let stringRegex;

  try {
    stringValue = z.string().parse(value);
  } catch (e) {
    throw Error('conditionEngine:matchesRegex: controlValue must be a string');
  }

  try {
    stringRegex = z.string().parse(regex);
  } catch (e) {
    throw Error('conditionEngine:matchesRegex: regularExpression must be a string');
  }

  let expression;
  try {
    expression = new RegExp(stringRegex);
  } catch (e) {
    throw Error('conditionEngine:matchesRegex: invalid regex passed as control value');
  }
  return expression.test(stringValue);
}

function inRange(value: unknown, controlValue: unknown): boolean {
  if (!Array.isArray(controlValue) || controlValue.length !== 2) return true;
  const rangeMin: number = controlValue[0];
  const rangeMax: number = controlValue[1];
  return (
    convertToComparableValue(value) > convertToComparableValue(rangeMin) &&
    convertToComparableValue(value) < convertToComparableValue(rangeMax)
  );
}

function notEmpty(value: unknown): boolean {
  const comparableValue = convertToComparableValue(value);
  if (_.isFinite(comparableValue)) return true;
  return !_.isEmpty(comparableValue);
}

function matchesAnyOperator(value: unknown, controlValue: unknown): boolean {
  const values: (string | number)[] = convertToComparableValues(value);
  const controlValues = convertToComparableValues(controlValue);

  return (
    !!values.length &&
    !!controlValues.length &&
    controlValues.some((control) => values.some((item) => item === control))
  );
}

function evaluateCollectionCondition(
  condition: ConditionCollection,
  resolver: IReadOnlyAnswerResolver,
  scope: InstanceScope,
  timezone: Timezone,
  currentDateOverride: string | null,
): EvaluateConditionsValues {
  function getCollection(): any[] {
    const collection = resolver.usingNodeId().getCollection(condition.collection, scope);
    return collection ?? [];
  }

  // Replace/insert `instanceIdentifier` at the correct place in the identifier array.
  function getCollectionIdentifiers(instanceIndex: number): InstanceScope {
    return resolver.usingNodeId().withCollectionIdentifier(scope, condition.collection, instanceIndex);
  }

  function getEvaluatedCollectionConditions(): boolean[] {
    return getCollection().map((__collectionInstance: unknown, instanceIdentifier: number) => {
      const conditionEvaluation = evaluateConditions(
        { conditions: condition.conditions },
        resolver,
        getCollectionIdentifiers(instanceIdentifier),
        timezone,
        currentDateOverride,
      );
      return conditionEvaluation.isValid;
    });
  }

  return {
    isValid: evaluateCollectionConditionToValue(condition, getEvaluatedCollectionConditions(), condition?.value),
  };
}

function evaluateCollectionConditionToValue(
  condition: ConditionCollection,
  evaluatedValues: boolean[],
  value?: unknown,
): boolean {
  const { collectionOperator } = condition;
  switch (collectionOperator) {
    case CollectionOperator.some:
      return _.some(evaluatedValues, Boolean);
    case CollectionOperator.none:
      return !_.some(evaluatedValues, Boolean);
    case CollectionOperator.every:
      return _.every(evaluatedValues, Boolean);
    case CollectionOperator.count:
      if (!_.isNumber(value)) {
        throw Error('A numerical value must be provided to perform CollectionOperator.count operation');
      }

      const count = evaluatedValues.filter((value) => value).length;
      return evaluateCondition(condition, count, value);
  }
  return assertUnreachable(collectionOperator);
}

function convertToComparableValue(value: unknown): string | number | (string | number)[] {
  if (Array.isArray(value)) return value;
  return typeof value === 'string' ? value : Number(value);
}

function convertToComparableValues(value: unknown): (string | number)[] {
  const parsedValue = convertToComparableValue(value);

  return Array.isArray(parsedValue) ? parsedValue : [parsedValue];
}
